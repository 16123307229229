import axios from "axios";
import Config from "../config";
// import { MessagesKit } from '../Kits';
const ApiClient = axios.create({
  baseURL: `${process.env.NODE_ENV === 'production' ? Config.apiUrlProd : Config.apiUrl}/api/`,
  // baseURL: 'http://localhost:1338/api/',
  headers: {
    // Authorization: `Bearer ${localStorage.getItem('authToken')}` || ''
  }
});


ApiClient.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => Promise.reject(error),
);

export const setClientToken = (token:any) => {
  if (token) {
    ApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const resetClientToken = () => {
  delete ApiClient.defaults.headers.common.Authorization;
};

export default ApiClient
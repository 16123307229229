type GeneratorTypeProps = {
  name:string,
  findArr:any[],
}

export const randomNameGenerator = (props:GeneratorTypeProps) => {
  let randomName = `${props.name}${Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000}`
  if(props.findArr.find((item:any) => item.name === randomName)){
    randomNameGenerator(props)
  } else {
    return randomName
  }
}

export const randomIdGenerator = () => {
  let randomName = `${Math.floor(Math.random() * (9999999999999 - 1000000000 + 1)) + 10000}`
  return randomName
}
import Config from "../config";
import ApiClient from "./apiClient";


export type AssistantApiProps = {
  prompt: string;
  chatId?: string | null;
}
const authService = {
  ask: (data:AssistantApiProps) => fetch(`${process.env.NODE_ENV === 'production' ? Config.apiUrlProd : Config.apiUrl}/api/ask-assistant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }),
  getChat:(id?:string) => ApiClient.post(`chat-room`, {id})
}

export default authService
import styled, { keyframes } from 'styled-components';

type MessageProps = {
  isUser: boolean;
  loadingAnswer?: boolean;
}


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
  /* gap:20px; */
  background-color: #FDFDFD;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: transparent;

  }
  &::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    box-shadow: inset 0 0 2px 2px #BFBFBF;
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  &::-webkit-scrollbar-button{
    display: none;
  }
`
export const MessageContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: transparent;

  }
  &::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    box-shadow: inset 0 0 2px 2px #BFBFBF;
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  &::-webkit-scrollbar-button{
    display: none;
  }
  
`
export const MessagesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;


`
export const MessageWrapper = styled.div<MessageProps>`
  display: flex;
  box-sizing:border-box;
  align-items: center;
  justify-content: ${({isUser}) => isUser ? 'flex-end':'flex-start'};
  padding-bottom: 20px;
  
`
export const MessageStyled = styled.div`
  display: flex;
  padding: 12px 14px 12px 14px;
  border-radius: 8px;
  /* max-width: 300px; */
  /* min-width: 150px; */
  text-align: left;
  font-family: e-Ukraine;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;

`
export const MessageThinked = styled(MessageStyled)`
  padding: 0px;
  background: linear-gradient(90deg, #000000 0%, #CACACA 100%);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 3s ease infinite;
`


export const MessageUser = styled(MessageStyled)`
  background-color:#000000;
  color:#FFFFFF;
`

export const MessageBot = styled(MessageStyled)`
  background-color:#E7EEF3;
  color:#000000;

`

export const InterractiveButton = styled.div`
  display: flex;
  padding: 10px;
  border-radius:8px;
  background: #D1DDE6;
  //styleName: Desktop/Base/Small;
  font-family: e-Ukraine;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  &:hover{
    background: #DBE5ED;
  }
  &:active{
    background: #C7D5DE;
  }
`
import styled from 'styled-components';



export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  gap:20px;
  background-color: #FDFDFD;

`

export const IllustrationImage = styled.img`
  /* width: 212px; */
  /* height: 213px; */
  margin-bottom: 4px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const Title = styled.h3`
  margin:0px;
  padding: 0px;
  font-size:28px;
  line-height:32px;
  font-weight: 400;
  /* font-family: e-Ukraine; */
  color:#000000;
`

export const SubTitle = styled.h3`
  margin:0px;
  padding: 0px;
  font-size:13px;
  line-height:18px;
  font-weight: 400;
  color: #353637;
  max-width:200px;
`
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import { MessageType, useChat } from '../../../../context/chat.context';
import {
  Container,
  MessageUser,
  MessageBot,
  MessageWrapper,
  MessagesWrapper,
  MessageContainer,
  MessageThinked,
  InterractiveButton,
} from './styles'
import MarkdownPreview from '@uiw/react-markdown-preview';
import AssistantIc from './assistant-ic.svg'
import { randomIdGenerator } from '../../../../utils/create-random-name';
import config from '../../../../config';
import './styles.css'
import { useTranslation } from 'react-i18next';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';
import ImageViewer from 'components/imageViewer';

const StyledMd = styled(MarkdownPreview)<{isSmall:boolean}>`
  &>table{
    width: 100%;
    max-width: ${({isSmall}) => isSmall ? '270px' : '602px'};
    overflow-x: scroll;
    padding-bottom:4px;

    &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: transparent;

    }
    &::-webkit-scrollbar-thumb {
      background-color: #BFBFBF;
      box-shadow: inset 0 0 2px 2px #BFBFBF;
      border-width: 0 2px 0 2px;
      border-color: rgba(28,28,28,0);
      border-style: solid;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      margin: 12px 0 12px 0;
    }
    &::-webkit-scrollbar-button{
      display: none;
    }
  }
  
`

const Messages:React.FC<any> = () => {
  const {
    messages,
    responseText,
    loadingAnswer,
    loadingAnswerPrinted,
  } = useChat()

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth',block:'end'});
  };
  console.log('responseText',responseText);
  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages or loading state changes
  }, [messages, loadingAnswer,loadingAnswerPrinted]);
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [responseText]);
  const [t] = useTranslation()

  return (
    <Container ref={messagesContainerRef}>
      <MessagesWrapper>
        {messages.map((message:MessageType) => (
          <MessageWrapper key={message?.id} isUser={message?.isUser}>
            <Message {...message} />
          </MessageWrapper>
        ))}
        {loadingAnswer && 
          <MessageWrapper isUser={false}>
            <Message 
              loadingAnswer={loadingAnswer}
              message={t("thinking")}
              isUser={false}
              id={randomIdGenerator()}
              timestamp={new Date()}
            />
          </MessageWrapper>
        }
        <div ref={messagesEndRef} />
      </MessagesWrapper>
    </Container>
  )
}


export default Messages



const Message:React.FC<any> = (message) => {
  const [processedMessage, setProcessedMessage] = useState('');


  const {
    isLarge,
    handleAsk,
  } = useChat()
  useEffect(() => {
    const processMessage = async () => {
      if (message?.message) {
        const result = await transformMarkdown(message.message);
        setProcessedMessage(result); // Сохраняем результат в состояние
        
      }
    };

    processMessage();
  }, [message]);


  const checkImageExists = (src: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(true); // Изображение существует
      img.onerror = () => resolve(false); // Изображение не существует
    });
  };

  // const transformMarkdown = (source: string) => {
  //   const regex = /\\\[\\\[\\\[\\\[(.*?)\\\]\\\]\\\]\\\]/g;
  //   const regexImgs = /{{{{(.*?)}}}}/g;
  //   const regexImgsTwo = /{{(.*?)}}/g;
  //   return source?.replaceAll(/【.*?】/g, '').replaceAll(regex, (match, p1) => {

  //     return `<div data-button="true" class='button-int'>
  //       ${p1}
  //     </div>`;
  //   }).replaceAll(regexImgs, (match, p1) => {
  //     return `![img bot](${p1})`; // Возвращаем только ссылку, убрав {{ }}
  //   })
  //   .replaceAll(regexImgsTwo, (match, p1) => {
  //     return `![img bot](${p1})`; // Возвращаем только ссылку, убрав {{ }}
  //   });
  // };
  const transformMarkdown = async (source: string): Promise<string> => {
    const regex = /\\\[\\\[\\\[\\\[(.*?)\\\]\\\]\\\]\\\]/g;
    const regexImgs = /{{{{(.*?)}}}}/g;
    const regexImgsTwo = /{{(.*?)}}/g;
    
    // Обработка изображений с асинхронной проверкой их наличия
    const replaceImages = async (source: string) => {
      // @ts-ignore
      const imageMatches = [...source.matchAll(regexImgs), ...source.matchAll(regexImgsTwo)];
  
      const replacements = await Promise.all(
        imageMatches.map(async (match) => {
          const url = match[1]; // Извлекаем URL изображения
          const exists = await checkImageExists(url); // Проверяем наличие изображения
          return {
            match: match[0], // Оригинальное совпадение
            replacement: exists ? `![img bot](${url})` : '', // Заменяем ссылку только если изображение существует
          };
        })
      );
  
      // Применяем замены
      let updatedSource = source;
      replacements.forEach(({ match, replacement }) => {
        updatedSource = updatedSource.replace(match, replacement);
      });
  
      return updatedSource;
    };
  
    let result = source?.replaceAll(/【.*?】/g, '');
  
    // Синхронно заменяем кнопки
    result = result.replaceAll(regex, (match, p1) => {
      return `<div data-button="true" class='button-int'>${p1}</div>`;
    });
  
    // Асинхронно обрабатываем ссылки на изображения
    result = await replaceImages(result);
  
    return result;
  };
  
  const chatId = localStorage.getItem('chatId');
  const handleClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const clickedText:any = (event.target as HTMLDivElement).textContent;
  
    // Check if the clicked element or its parent is the target button
    if (target.dataset.button) {
      console.log('Div clicked!',clickedText);
      const cleanedText = clickedText.trim();
      console.log('Div cleanedText!',cleanedText);

      handleAsk({prompt:cleanedText, chatId:chatId})
      // Add any other logic here
    }
  };

  if(!message || message === null) return null;
  if(message.isUser)return(
    <MessageUser>
      {message.audio ?
      <audio controls src={`${process.env.NODE_ENV === 'production' ? config.apiUrlProd : config.apiUrl}${message.audio.url}`} />
      :message.message}
    </MessageUser>
  )
  if(message.loadingAnswer)return(
    <MessageContainer style={{alignItems:'center'}}>
      <img src={AssistantIc} alt='assistant'/>
      <MessageThinked>
        {message.message}
      </MessageThinked>
    </MessageContainer>
  )
  return(
    <MessageContainer>
      <img src={AssistantIc} alt='assistant'/>
      <MessageBot className="light-theme" onClick={handleClick}>

      {message.audio ?
      <audio controls src={`${process.env.NODE_ENV === 'production' ? config.apiUrlProd : config.apiUrl}${message.audio.url}`} />
      :
        <StyledMd
          isSmall={!isLarge}
          // className='markdown-custom'
          source={processedMessage}
          style={{display:'flex', alignItems:'flex-start', flexDirection:'column', padding: 0, backgroundColor: 'transparent', color:'#000000',}}
          rehypePlugins={[rehypeRaw]} // This allows you to use HTML in markdown
          components={{
            //@ts-ignore
            img: ({ src, alt }:{src:any, alt:any}) => (
              <ImageViewer
                thumbnailSrc={src}
                fullSrc={src} // Используем одно и то же изображение для примера
                alt={alt || 'Image preview'}
                width={800}  // Ширина и высота полноэкранного изображения
                height={600} // Можете настроить их под свои нужды
              />
            ),
          }}
          rehypeRewrite={(node:any, index:any, parent:any) => {
            // Ваш существующий код
            if (node.tagName === "a" && parent && /^h(1|2|3|4|5|6)/.test(parent.tagName)) {
              parent.children = parent.children.slice(1);
            }
            
            // Логика для замены узлов текста на кастомный компонент
            // if (node.type === 'text' && node.value) {
            //   const regex = /\[\[\[\[(.*?)\]\]\]\]/g;
            //   node.value = node.value.replaceAll(regex, (_:any, p1:any) => {
            //     return `${p1}`;
            //   });
            // }
          }}
          // components={{
          //   //@ts-ignore
          //   InterractiveButton: InterractiveButton,
          // }}
        />
      }
      </MessageBot>
    </MessageContainer>
  )
}


import styled from 'styled-components';



export const Container = styled.div`
  width:100%;
  display: flex;
  box-sizing: border-box;
  padding: 10px 10px 10px 20px;
  justify-content: space-between;
  border-bottom: 1px solid #EAEAEA;
  background-color: #FFF;
  border-radius: 20px 20px 0px 0px;
    /* height: 60px; */
  /* overflow: hidden; */
  @media screen and (max-width: 768px){
    height: 60px;
    align-items: center;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap:10px;
`

export const Title = styled.h3`
  margin:0px;
  padding: 0px;
  font-size:13px;
  line-height:18px;
  font-weight: 400;
  color:#000000;
`

export const ImageResize = styled.img`
  &:hover{
    cursor: pointer;
    background: #E7EEF3;
    border-radius: 8px;
  }
  width:40px;
  height:40px;
  display: block;
  @media screen and (max-width: 768px){
    display: none;
  }
`
export const ImageReboot = styled.img`
  &:hover{
    cursor: pointer;
    background: #E7EEF3;
    border-radius: 8px;
  }
  width:40px;
  margin-right: 10px;
  height:40px;
  display: block;

`
export const ImageClose = styled.img`
  &:hover{
    cursor: pointer;
  }
  display: none;
  width:18px;
  height:18px;
  @media screen and (max-width: 768px){
    display: block;
  }
`
'use client'
import * as React from 'react';
import {
  Container
} from './styles'
import {
  Header,
  Input,
  Messages,
  NoMessages,
} from './components'
import { useChat } from '../../context/chat.context';

const ChatPage:React.FC = () => {
  const {
    messages
  } = useChat()
  return(
    <Container>
      <Header hasNameAssistant={messages?.length > 0}/>
      {messages?.length === 0 ? <NoMessages /> : <Messages />}
      <Input />
    </Container>
  )
}

export default ChatPage
import React from 'react';
import './i18n';
import {ChatProvider} from './context/chat.context'
function App() {
  return (
    <ChatProvider>
    </ChatProvider>
  );
}

export default App;

import * as React from 'react';
import {
  Container,
  IllustrationImage,
  TitleWrapper,
  Title,
  SubTitle,
} from './styles'
import Illustration from './image.svg'
import { useTranslation } from 'react-i18next';

const NoMessages:React.FC<any> = () => {
  const [t] = useTranslation()
  return (
    <Container>
      <IllustrationImage alt='illustration' src={Illustration}/>
      <TitleWrapper>
        <Title>{t("title")}</Title>
        <SubTitle>{t("description")}</SubTitle>
      </TitleWrapper>

    </Container>
  )
}


export default NoMessages
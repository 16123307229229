import styled from 'styled-components';

type SendProps = {
  isLoading: boolean;
}
export const InputWrapper = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  @media screen and (max-width: 768px){
    width:calc(100vw - 30px);
  }
`
export const RewcordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:2px;
  width:100%;
`

export const PlayIcon = styled.img<SendProps>`
  width: 30px;
  height: 30px;
  padding: 9px;
  gap: 10px;
  border-radius: 8px;
  background: #F3F3F3;
  opacity: ${({isLoading}) => isLoading ? "0.5" : "1"};
  &:hover{
    cursor: ${({isLoading}) => isLoading ? "" : "pointer"};
  }
`
export const RecordTime = styled.p`
  font-family: e-Ukraine;
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  color: #000000;

`
export const Container = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  align-items: flex-start!important;
  background-color: #fff;
  align-items: flex-start;
  border: 1px solid #EAEAEA;
  /* height: 67px; */
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0px 0px 20px 20px;
  /* height: Hug (60px)px; */
  padding: 15px 10px 15px 20px;
  border: 1px 0px 0px 0px;
  @media screen and (max-width: 768px){
    /* padding: 15px 8px 15px 12px; */
  }
`
export const ImageSend = styled.img<SendProps>`
  opacity: ${({isLoading}) => isLoading ? "0.5" : "1"};
  &:hover{
    cursor: ${({isLoading}) => isLoading ? "" : "pointer"};
  }
`
export const InputContainer = styled.textarea`
  /* padding-left: 16px; */
  /* padding-right: 100px; */
  /* padding-top: 10px; */
  box-sizing: border-box;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  width:100%;
  align-items: center;
  /* min-width:100%; */
  /* max-width:100%; */
  /* min-height: 59px; */
  /* min-height: 59px; */
  /* max-height: 15px; */
  max-height: 300px;
  padding-right:20px;
  resize: none; /* Disable manual resizing */
  overflow-y: hidden; /* Hide vertical scrollbar initially */
  border:none;
  color:#000;
  -moz-appearance: textfield; /* Firefox */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  &:hover {
  }
  &::placeholder {
    color:#959595;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 400;

  }
  &:focus-visible{
    /* background: #FFFFFF; */
    background: transparent;
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      /* -webkit-text-fill-color: #fff !important; */
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;

  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: inset 0 0 2px 2px #BFBFBF;
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  &::-webkit-scrollbar-button{
    display: none;
  }
  @media screen and (max-width: 768px){
    /* height: 30px; */
    box-sizing:border-box;
    /* flex: none; */
    /* width:100%; */
    font-size: 16px;
    padding-top: 0px;

  }
`
export const ArrowSendAudio = styled.div`
  display: flex;
  /* height: 30px; */
  padding: 2px 6px 2px 8px;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  background: #F3F3F3;
  &:hover{
    cursor: pointer;
    /* opacity: 0.8; */
  }
`
export const Options = styled.ul<{active:boolean, isFooter?:boolean}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width:115px;
  bottom: 40px;
  right: 0px;
  /* border: 1px solid #ccc; */
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  list-style: none;
  margin: 0;
  z-index: 1;
  padding: 4px 0px;
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  opacity:${({active}) => active ? '1' : '0'};
  transform-origin: bottom right;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  &>li {
    display: flex;
    gap: 8px;
    padding: 8px 10px;
    cursor: pointer;
    color:#000;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &>li:hover {
    //background-color: #f0f0f0;
    color:rgba(77, 161, 255, 1);
  }

`
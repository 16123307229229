import React, { useEffect, useRef, useState, useMemo } from 'react';
// import Config from "../Config";
import axios from 'axios';
import Chat from '../components/chat';
import IcChat from './top.svg'
import IcChevron from './Chevron_down.svg'
import styled from 'styled-components'
import { assistantService } from '../api';
import { randomIdGenerator, randomNameGenerator } from '../utils/create-random-name';
import IcDurachok from './dur.svg'
import IcClose from './ic-close-gray.svg'
// import { useParams } from 'next/navigation';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { getDictionary } from '../../get-dictionary';
type PageProps = {
  active: boolean;
  isLarge?: boolean;
}

const ViewDurachokWrapper = styled.div`
  position: fixed;
  z-index:20;
  margin:20px;
  right:0px;
  bottom:80px;
  display: flex;
  align-items: flex-end;
  gap:7px;

`
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
const DurInfo = styled.p`

  font-family: e-Ukraine;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  /* marg */

`
const BlockInfo = styled.div`
  display:flex;
  flex-direction: column;
  width:260px;
  box-sizing: border-box;
  /* align-items: flex-start; */
  height: 109px;
  top: 177px;
  left: 207px;
  padding: 8px 8px 16px 16px;
  /* gap: 4px; */
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px 0px #0000001A;

`

const ChatPageComponent:any = styled.div<PageProps>`
  display: flex;
  flex-direction: column;
  height:${({isLarge}) => isLarge ? '964px' : '708px'};
  max-height:calc(100% - 120px);
  width:${({isLarge}) => isLarge ? '737px' : '400px'};
  position: fixed;
  z-index:20;
  /* background-color:#000; */
  margin:20px;
  right:0px;
  bottom:80px;
  box-sizing: border-box;
  border-radius:20px;
  box-shadow: 0px 2px 35px 0px #00000033;
  transform:${({active}) => active ? 'scale(1)' : 'scale(0)'};
  transform-origin: bottom right;
  transition: 0.2s all ease-in-out;
  /* transition: 0.2s opacity, height ease-in-out; */
  opacity:${({active}) => active ? '1' : '0'};
  @media screen and (max-width: 768px){
    width:100vw;
    height:100vh;
    max-height:100%;
    margin:0px;
    bottom:0px;
    z-index:23;
    transition:none;
    border-radius:0px;
  }
`
const ChatIcon = styled.img<PageProps>`
  display: flex;
  position: fixed;
  z-index:20;
  right:20px;
  transition: 0.1s transform ease-in-out;
  bottom:20px;
  &:hover{
    cursor:pointer;
    transform:scale(1.142857)
  }
  &:active{
    transform:scale(0.857143)
  }
`
export type MessageType = {
  message: string;
  id: number|string;
  timestamp: Date;
  isUser: boolean;
}

interface ChatContextInterface {
  messages:MessageType[],
  responseText:any;
  handleAsk:any;
  chatModal:boolean,
  setChatModal:(status:boolean) => void,
  isLarge:boolean,
  setIsLarge:(status:boolean) => void,
  messageText:string,
  setMessageText:(text:string) => void,
  loadingAnswer:boolean,
  loadingAnswerPrinted:boolean,
  rebootChat:any,
}

const ChatContext = React.createContext<Partial<ChatContextInterface>>({});

const ChatProvider = (props:any) => {
  const [isLarge, setIsLarge] = useState<boolean>(true)
  const [messages, setMessages] = useState<any[]>([])
  const [chatModal, setChatModal] = useState<boolean>(false)
  const [responseText, setResponseText] = useState('');
  const [messageText, setMessageText] = useState('');
  const [loadingAnswer, setLoadingAnswer] = useState<boolean>(false)
  const [loadingAnswerPrinted, setLoadingAnswerPrinted] = useState<boolean>(false)
  const [preloading, setPreloading] = useState<boolean>(true)
  const [viewDurachok, setViewDurachok] = useState<boolean>(false)

  const params:any = useParams()
  const [t] = useTranslation()
  const PreloadFunc = () => {
    const chatId = localStorage.getItem('chatId');
    assistantService.getChat(chatId || randomIdGenerator())
    .then((res) => {
      localStorage.setItem('chatId', res.data.randomId)
      if(res.data?.messages?.length > 0){
        setMessages(res.data?.messages.map((message:any) => {
          return {
            audio:message.audio,
            message: message.text,
            id:message.id,
            timestamp:  message?.createdAt,
            isUser: !message?.isAssistant,
          }
        }))
      }

      setPreloading(false)
    })
  }
  useEffect(() => {
    PreloadFunc()
  },[])

  const handleAsk = async (body:any) => {
    let chatId:any
    chatId = await localStorage.getItem('chatId');
    if(!chatId){
      localStorage.setItem('chatId',randomIdGenerator())
      chatId = localStorage.getItem('chatId');
    }
    
    await setMessages((prev) => [...prev, {
      audio:body?.fileUrl ? {url:body.fileUrl} : null,
      message: messageText || body?.prompt,
      id: randomNameGenerator({name:'userMessage',findArr:messages}),
      // id:index,
      timestamp:  new Date(),
      isUser: true,
    }])
    try{
      await setMessageText('')
      await setLoadingAnswer(true)
      await setLoadingAnswerPrinted(true)
      const response:any = await assistantService.ask(body ? body : {prompt:messageText, chatId:chatId})
      if(body?.fileUrl){
        const responsed = await response.json();
        setMessages((prev) => [...prev, {
          message: responsed.text,
          id: randomNameGenerator({name:'userMessage',findArr:messages}),
          // id:index,
          timestamp:  new Date(),
          isUser: false,
          isUrl:true,
          audio:responsed.audio,
        }])
        await setLoadingAnswer(false)
        await setLoadingAnswerPrinted(false)
      } else {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = '';
        await setLoadingAnswer(false)
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            await setLoadingAnswerPrinted(false)
            await setMessages((prev) => [...prev, {
              message: result,
              // id: randomNameGenerator({name:'userMessage',findArr:messages}),
              // timestamp:  new Date(),
              isUser: false,
            }])
            await setResponseText('')
  
            break;
          }
          result += await decoder.decode(value, { stream: true });
          await setResponseText(prev => prev + decoder.decode(value, { stream: true }));
        }
        
      }

    } catch (e) {
      console.log('err', e);
      await setLoadingAnswer(false)
      await setLoadingAnswerPrinted(false)
    }

  };
  const rebootChat = () => {
    localStorage.removeItem('chatId')
    setMessages([])
    setLoadingAnswer(false)
    setLoadingAnswerPrinted(false)
    setResponseText('')
  }

  return (
    <ChatContext.Provider
      value={{
        messages: loadingAnswerPrinted&& responseText?.length > 0 ? [...messages, {
          message: responseText,
          // id: '772722-key',
          // timestamp:  new Date(),
          isUser: false,
        }] : messages,
        chatModal,
        // messages:memoizedMessages,
        setChatModal,
        isLarge,
        setIsLarge,
        responseText,
        handleAsk,
        messageText,
        setMessageText,
        loadingAnswer,
        loadingAnswerPrinted,
        rebootChat
      }}
      {...props}
    >
      <>
        {props.children}
        {preloading ? null :
          <>
          {viewDurachok && !chatModal && messages.length === 0 && 
            <ViewDurachokWrapper>
              <img src={IcDurachok} alt='IcDurachok'/>
              <BlockInfo>
                <Header>
                  <img onClick={() => setViewDurachok(false)} style={{cursor:'pointer'}}  src={IcClose} alt='IcClose'/>
                </Header>
                <DurInfo style={{marginBottom:'16px'}}>
                  {t("title1")}
                </DurInfo>
                <DurInfo>
                  {t("title2")}
                </DurInfo>
              </BlockInfo>
            </ViewDurachokWrapper>
          }
            <ChatPageComponent isLarge={isLarge} active={chatModal}>
              <Chat/>
            </ChatPageComponent>
            <ChatIcon
              alt='IconChat'
              active={chatModal}
              src={chatModal ? IcChevron : IcChat}
              onClick={() => setChatModal(!chatModal)}
            />
          </>
        }
      </>
    </ChatContext.Provider>
  );
};

const useChat = () => {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context as ChatContextInterface;
};

export { ChatProvider, useChat };

import * as React from 'react';
import {
  Container,
  ImageResize,
  TitleWrapper,
  Title,
  ImageClose,
  ImageReboot
} from './styles'
import IcResize from './ic-size.svg'
import IcClose from './ic-close.svg'
import IcReboot from './ic-reboot.svg'
import IcChat from './ic-assistant.svg'
import { useChat } from '../../../../context/chat.context';
import { useTranslation } from 'react-i18next';
type Props = {
  hasNameAssistant:boolean;
  data?:any;
}
const Header:React.FC<Props> = ({hasNameAssistant, data}) => {
  const {
    isLarge,
    setIsLarge,
    setChatModal,
    rebootChat
  } = useChat()
  const [t] = useTranslation()
  return (
    <Container>
      {hasNameAssistant ? 
        <TitleWrapper>
          <img alt='ic-chat' src={IcChat} />
          <Title>{t("helper")}</Title>
        </TitleWrapper>
      :
        <div/>
      }
      <div style={{display:'flex', alignItems:'center'}}>
        <ImageReboot alt='resize-image' onClick={rebootChat} src={IcReboot}/>
        <ImageClose alt='resize-close' onClick={() => setChatModal(false)} src={IcClose}/>
        <ImageResize alt='resize-image' onClick={() => setIsLarge(!isLarge)} src={IcResize}/>
      </div>
    </Container>
  )
}


export default Header
import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    overflow: ${(props: { isOpen: boolean }) => (props.isOpen ? 'hidden' : 'auto')};
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const ImageThumbnail = styled.img`
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.02);
  }
`;

const FullscreenOverlay = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;

const FullscreenImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

interface ImageViewerProps {
  thumbnailSrc: string;
  fullSrc: string;
  alt: string;
  width: number;
  height: number;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ thumbnailSrc, fullSrc, alt, width, height }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isImageValid, setIsImageValid] = useState<boolean | null>(null);
  const thumbnailUrl = useMemo(() => thumbnailSrc, [thumbnailSrc]);
  const fullImageUrl = useMemo(() => fullSrc, [fullSrc]);
  // useEffect(() => {
  //   const img = new Image();
  //   img.src = thumbnailSrc;
  //   img.onload = () => setIsImageValid(true);
  //   img.onerror = () => setIsImageValid(false);
  // }, [thumbnailSrc]);

  const openImage = () => setIsOpen(true);
  const closeImage = () => setIsOpen(false);

  // if (isImageValid === null || !isImageValid) {
  //   // Пока проверяем изображение, ничего не показываем
  //   return null;
  // }

  return (
    <>
      <GlobalStyle isOpen={isOpen} />
      <ImageThumbnail src={thumbnailUrl} alt={alt} onClick={openImage} />
      {isOpen &&
        ReactDOM.createPortal(
          <FullscreenOverlay isOpen={isOpen} onClick={closeImage}>
            <FullscreenImage width={width} height={height} src={fullImageUrl} alt={alt} />
          </FullscreenOverlay>,
          document.body // Рендерим компонент в `body`
        )}
    </>
  );
};


export default React.memo(ImageViewer);

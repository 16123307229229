import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  InputContainer,
  ImageSend,
  InputWrapper,
  RecordTime,
  RewcordWrapper,
  PlayIcon,
  ArrowSendAudio,
  Options
} from './styles'
import IcSend from './ic-send.svg';
import IcDelete from './ic-delete.svg';
import IcStop from './Vector.svg';
import IcMic from './ic-microphone.svg';
import { assistantService } from '../../../../api';
import { useChat } from '../../../../context/chat.context';
import axios from 'axios';
import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize';
import IcClose from './ic-close.svg'
import config from '../../../../config';
import IcArrow from './Drill-in.svg'
import { useTranslation } from 'react-i18next';

const Input:React.FC<any> = () => {
  const {
    handleAsk,
    messageText,
    setMessageText,
    loadingAnswer,
    loadingAnswerPrinted,
  } = useChat()
  const textareaRef:any = useRef(null);

  useEffect(() => {
    const listener = (event:any) => {
      if (textareaRef.current?.value?.length > 0 && (!isRecording) && (event.code === "Enter" || event.code === "NumpadEnter") && !loadingAnswer && !loadingAnswerPrinted) {
        handleAsk({prompt:textareaRef.current?.value ? textareaRef.current?.value : ''})
        event.preventDefault();
        textareaRef.current.value = ''; // Очистить текстовое поле
        // handleUpdate()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });
  const [t] = useTranslation()
  const [height, setHeight] = useState('auto');
  const [overflow, setOverflow] = useState('hidden');

  const adjustHeight = () => {
    if (textareaRef.current) {
        //@ts-ignore
      textareaRef.current.style.height = '15px'; // Reset height to auto
        //@ts-ignore
      const newHeight = textareaRef.current.scrollHeight;
      // console.log('newHeight',newHeight);
      if (newHeight <= 300) {
      // console.log('if',newHeight);
        //@ts-ignore
      textareaRef.current.style.height = `${newHeight}px`;
        setHeight(`${newHeight}px`);
        setOverflow('hidden');
      } else {
      // console.log('else',newHeight);
        //@ts-ignore
      textareaRef.current.style.height = '300px';
        setHeight('300px');
        setOverflow('auto');
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  });
  const handleClick = () => {
    if((!audioBlob && !isRecording)){
    //@ts-ignore
      textareaRef.current.focus();
    }
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<any>(null);
  const [recordTime, setRecordTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);
  const audioChunksRef:any = useRef([]);
  const intervalRef:any = useRef(null);
  const visualizerRef:any = useRef(null);
  const [audioURL, setAudioURL] = useState(null);
  useEffect(() => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      const handleDataAvailable = (event:any) => {
        console.log('event:', event);
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.addEventListener('dataavailable', handleDataAvailable);

      return () => {
        mediaRecorder.removeEventListener('dataavailable', handleDataAvailable);
      };
    }
  }, [mediaRecorder]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        recorder.start();
        setIsRecording(true);
        setAudioBlob(null);  // Сброс старого файла при начале новой записи
        setAudioURL(null);
        setRecordTime(0);
        startTimer();
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      stopTimer();
      mediaRecorder.onstop = () => {
        // Supported formats: ['flac', 'm4a', 'mp3', 'mp4', 'mpeg', 'mpga', 'oga', 'ogg', 'wav', 'webm']

        const audioBlob1 = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        console.log('audioBlob1',audioBlob1);
        audioChunksRef.current = [];
        setAudioBlob(audioBlob1);
        const url:any = URL.createObjectURL(audioBlob1);
        setAudioURL(url);


      };
    }
  };
  const sendFile = (lang:any) => {
    const formData = new FormData();
    formData.append('files', audioBlob, 'audio.mp3');
    const chatId = localStorage.getItem('chatId');

    axios.post(`${process.env.NODE_ENV === 'production' ? config.apiUrlProd : config.apiUrl}/api/upload`, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
      },
    })
    // fetch('http://localhost:1338/api/upload', {
    //   method: 'post',
    //   body: formData
    // })
    .then((response:any) => {
      // axios.post('http://localhost:1338/api/ask-assistant', {
      //   fileUrl:`${response.data?.[0].url}`,
      //   chatId:chatId
      // })
      
      handleAsk({
        fileUrl:`${response.data?.[0].url}`,
        lang:lang,
        chatId:chatId,
        fileId:response.data?.[0].id
      })
      skip()
      // const returnedAudioURL = response.data.audioURL;
      // const audio = new Audio(returnedAudioURL);
      // audio.play();
    })
    .catch(error => {
      console.error('Error uploading audio:', error);
    });
  }

  const resetRecording = () => {
    setAudioBlob(null);
    setAudioURL(null);
    setRecordTime(0);
  };
  const skip = () => {
    mediaRecorder.stop();
    setIsRecording(false);
    stopTimer();
    audioChunksRef.current = [];
    setAudioBlob(null);
    setAudioURL(null);
    setRecordTime(0);
  }
  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setRecordTime(prevTime => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
  };
  const formatTime = (seconds:any) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  // console.log('audioblob');
  // const textareaRef:any = useRef<HTMLTextAreaElement | null>(null);
  // const handleInputChange = () => {
  //   if (textareaRef.current) {
  //     messageTextRef.current = textareaRef.current.value; // Update ref value
  //   }
  // };

  
  return (
    <Container onClick={() => handleClick()} style={messageText?.length > 0 ? {alignItems:'flex-start'} : {alignItems:'center'}}>
      <RewcordWrapper>
        {isRecording && mediaRecorder && (
          <LiveAudioVisualizer
            mediaRecorder={mediaRecorder}
            width={500}
            barColor={'#027FFE'}
            height={50}
            barWidth={2}
            //@ts-ignore
            style={{width:'100%'}}

          />
        )}
        {(!isRecording && audioBlob) && (
          <AudioVisualizer
            ref={visualizerRef}
            blob={audioBlob}
            width={500}
            height={50}
            barWidth={2}
            style={{width:'100%'}}
            // gap={0}
            barColor={'#027FFE'}
          />

        )}
        {(audioBlob || isRecording) &&
          <RecordTime>{formatTime(recordTime)}</RecordTime>
        }
      </RewcordWrapper>


      {/* {audioURL && <audio controls src={audioURL} />} */}
      <InputWrapper>
      {(!audioBlob && !isRecording) &&
        <InputContainer
          // value={messageText}
          // onChange={(e) => handleInputChange(e.target.value)}
          ref={textareaRef}
          //@ts-ignore
          style={{ height, overflowY: overflow }}
          placeholder={t("message")}
        />
      }
      {(audioBlob || isRecording) &&
        <div style={{display:'flex', gap:'6px'}}>
          <PlayIcon
            isLoading={loadingAnswer || loadingAnswerPrinted}
            alt='image-IcStop'
            onClick={!loadingAnswer && !loadingAnswerPrinted ? stopRecording : () => {}}
            src={IcStop}
          />
          <PlayIcon
            isLoading={loadingAnswer || loadingAnswerPrinted}
            alt='image-IcDelete'
            onClick={!loadingAnswer && !loadingAnswerPrinted ? isRecording ? skip : resetRecording : () => {}}
            src={isRecording ? IcClose : IcDelete}
          />
        </div>

      }
      {(messageText?.length > 0 || audioBlob) &&
        // <ImageSend
        //   isLoading={loadingAnswer || loadingAnswerPrinted}
        //   alt='image-send'
        //   onClick={(!loadingAnswer && !loadingAnswerPrinted) ? audioBlob ? sendFile :  () => handleAsk('uk') : () => {}}
        //   src={IcSend}
        // />
        <ArrowSendAudio onClick={() => setIsOpen(!isOpen)}>
          <ImageSend
            isLoading={loadingAnswer || loadingAnswerPrinted}
            alt='image-send'
            // onClick={(!loadingAnswer && !loadingAnswerPrinted) ? audioBlob ? sendFile :  () => handleAsk('uk') : () => {}}
            src={IcSend}
          />
          <img src={IcArrow} alt='IcArrow'/>
          <Options active={isOpen} >
              <li
                onClick={(!loadingAnswer && !loadingAnswerPrinted) ? audioBlob ? () => sendFile('en') :  () => handleAsk() : () => {}}
                >
                English
              </li>
              <li
                onClick={(!loadingAnswer && !loadingAnswerPrinted) ? audioBlob ? () => sendFile('uk') :  () => handleAsk() : () => {}}
                >
                Ukrainian
              </li>
          </Options>
        </ArrowSendAudio>
      }

      {messageText?.length === 0 && !isRecording && !audioBlob &&
        <ImageSend
          isLoading={loadingAnswer || loadingAnswerPrinted}
          alt='image-IcMic'
          onClick={startRecording} 
          // onClick={!loadingAnswer && !loadingAnswerPrinted ? handleAsk : () => {}}
          src={IcMic}
        />
      }
      </InputWrapper>

    </Container>
  )
}


export default Input